<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />

    <div class="apipe-cloud-course-container-madeSolution">
      <div class="pc-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h1 style="margin-bottom: 15px;font-size:42px;font-weight:700;">优制云智能制造MES系统</h1>
          <div class="tit">
            优制云工业互联网平台智能制造MES产品，直击中小微企业面临的生产经营痛点。传统生产管理
          </div>
          <div class="tit">
            往往面临生产计划不透明、生产进度难以实时掌握、质量控制依赖人工且效率低下等难题。这些
          </div>
          <div class="tit">
            问题导致生产周期延长、成本攀升，甚至影响产品交付质量与客户满意度。
          </div>
          <!-- <div class="exp" @click="handleTest">免费试用</div> -->
        </div>
        
        <MessageInfo />

      </div>

      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="text-align: left">
          <div class="title">优制云智能制造MES系统</div>
          <div class="titwrap">
            <div class="tit">融合了物联网、大数据和人工智能技术，通过自动</div>
            <div class="tit">化生产线和智能决策支持，实现了生产优化和效率</div>
            <div class="tit">提升，助力企业实现智能化生产。</div>            
          </div>
        </div>
      </div>

      <!-- 行业痛点 -->
      <div class="pc-industry d-none d-lg-block">
        <div class="pro-tit">行业痛点</div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/hangye1.png" alt="" />
                <h6>信息不互通</h6>
                <div class="des">
                  <div>各部门信息不同步，数据难以共</div>
                  <div>享，导致决策缓慢</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/hangye2.png" alt="" />
                <h6>质量控制难</h6>
                <div class="des">
                  <div>缺乏实时监控，质量问题难以及</div>
                  <div>时发现和处理</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/hangye3.png" alt="" />
                <h6>生产效率低</h6>
                <div class="des">
                  <div>手工操作多，生产流程不透明，</div>
                  <div>效率难以提升</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/hangye4.png" alt="" />
                <h6>生产成本高</h6>
                <div class="des">
                  <div>生产成本难以精确计算，成本控</div>
                  <div>制困难</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>
      <div class="mobile-industry  d-block d-lg-none">
        <div class="pro-tit">行业痛点</div>
        <div class="container text-center">
          <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/hangye1.png" alt="" />
                <h6>信息不互通</h6>
                <!-- <div class="des">
                  <div>各部门信息不同步，数据难以共</div>
                  <div>享，导致决策缓慢</div>
                </div> -->
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/hangye2.png" alt="" />
                <h6>质量控制难</h6>
                <!-- <div class="des">
                  <div>缺乏实时监控，质量问题难以及</div>
                  <div>时发现和处理</div>
                </div> -->
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/hangye3.png" alt="" />
                <h6>生产效率低</h6>
                <!-- <div class="des">
                  <div>手工操作多，生产流程不透明，</div>
                  <div>效率难以提升</div>
                </div> -->
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/hangye4.png" alt="" />
                <h6>生产成本高</h6>
                <!-- <div class="des">
                  <div>生产成本难以精确计算，成本控</div>
                  <div>制困难</div>
                </div> -->
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <!-- 解决方案 -->
      <div class="container text-center">
        <div class="pro-tit d-none d-lg-block" style="font-size: 36px">解决方案</div>
        <div class="pro-tit d-block d-lg-none" style="font-size:20px;font-weight:700;margin-bottom:20px;">解决方案</div>
        <img
          style="width: 100%"
          src="@/assets/newSite/mes/soulution.png"
          alt=""
        />
      </div>

      <!-- 产品能力 -->
      <div class="pc-pro-power d-none d-lg-block">
        <div class="pro-tit" style="font-size: 36px">产品能力</div>
        <div>
          <div class="tabs container text-center">
            <div
              :class="['items', tbx == index ? 'active' : '']"
              v-for="(item, index) in tabListNew"
              :key="index"
              @click="handleTab(index)"
            >
              <img :src="item.img1" />
              <div class="tit">{{ item.tit }}</div>
            </div>
          </div>
          <div class="tab-cot">
            <div class="container text-center">
              <div class="row" v-show="tbx == 0">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">生产BOM、文件管理</div>
                  <div class="mcot">
                    <div>工单生产BOM和对应资料的管理，便于管理工单资料</div>
                    <div>核对生产BOM信息</div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/mes/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 1">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">生产工单、生产投料</div>
                  <div class="mcot">
                    <div>生产工单的信息和生产投料单的信息，便于生产核对物料信息</div>
                   
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                <img
                    src="@/assets/newSite/mes/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 2">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">生产上下线、生产过账、产品包装、产品入库、生产不良、生产巡检</div>
                  <div class="mcot">
                    <div>生产工单上下线、工艺流程中过站管理及入库和维修等，通过对生产工艺流程管控和对过站信息的记录，便于生产防呆和信息追溯</div>
                   
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                <img
                    src="@/assets/newSite/mes/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 3">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">IQC检验、FQC检验、IPQC、冻结解冻、质量异常</div>
                  <div class="mcot">
                    <div>管理来料质检、巡检、入库检、异常产品的冻结解冻和生产过程中质量异常的管理</div>
                   
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                <img
                    src="@/assets/newSite/mes/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 4">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">设备台账、配件管理、设备出入库、设备点检、维修、盘点、保养</div>
                  <div class="mcot">
                    <div>管理设备的基础信息、配件和出入库等，记录设备点检、保养、维修等信息，还可自动预警提醒及时保养、点检等</div>
                   
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                <img
                    src="@/assets/newSite/mes/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 5">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">设备数据采集</div>
                  <div class="mcot">
                    <div>采集设备的产量、投入人力和生产设备的相关参数等</div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                <img
                    src="@/assets/newSite/mes/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-pro-power d-block d-lg-none">
        <h4 class="pro-tit">产品能力</h4>
        <div class="">
          <div class="tabs">
            <div
              :class="['items', tbx == index ? 'active' : '']"
              v-for="(item, index) in tabListNew"
              :key="index"
              @click="handleTab(index)"
            >
              <img :src="item.img1" />
              <div class="tit">{{ item.tit }}</div>
            </div>
          </div>
          <div class="tab-cot">
            <div class="container text-center">
              <div class="row" v-show="tbx == 0">
                <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">生产BOM、文件管理</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>工单生产BOM和对应资料的管理</div>
                    <div>便于管理工单资料核对生产BOM信息</div>
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/mes/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 1">
                <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">生产工单、生产投料</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>生产工单的信息和生产投料单的信息，便于生产核对物料信息</div>
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/mes/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 2">
                <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">生产上下线、生产过账、产品包装、产品入库、生产不良、生产巡检</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>生产工单上下线、工艺流程中过站管理及入库和维修等，通过对生产工艺流程管控和对过站信息的记录，便于生产防呆和信息追溯</div>
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/mes/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 3">
                <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">IQC检验、FQC检验、IPQC、冻结解冻、质量异常</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>管理来料质检、巡检、入库检、异常产品的冻结解冻和生产过程中质量异常的管理</div>
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/mes/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 4">
                <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">设备台账、配件管理、设备出入库、设备点检、维修、盘点、保养</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>管理设备的基础信息、配件和出入库等，记录设备点检、保养、维修等信息，还可自动预警提醒及时保养、点检等</div>
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/mes/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 5">
                <div class="col-xs-12 col-md-6 lf" style="padding:0 26px;">
                  <h4 style="text-align: left">设备数据采集</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>采集设备的产量、投入人力和生产设备的相关参数等</div>
                    <!-- <div>便于管理工单资料核对生产BOM信息</div> -->
                  </div>
                </div>
                <div >
                  <img
                    src="@/assets/newSite/mes/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品优势 -->
      <div class="pc-scene d-none d-lg-block">
        <div class="pro-tit d-none d-lg-block">产品优势</div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/ad1.png"  alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/ad2.png"  alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/ad3.png"   alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/ad4.png"  alt="" />
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>
            <!-- 产品优势 -->
       <div class="mobile-scene d-block d-lg-none">
        <div class="pro-tit d-block d-lg-none" style="font-size:20px;font-weight:700;margin-bottom:20px;">产品优势</div>
        <div class="container text-center">
          <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/ad1.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/ad2.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/ad3.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/mes/ad4.png" alt="" />
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <!-- 成功案列 -->
      <section
        class="pc-product d-none d-lg-block"
        style="margin: 80px 0 50px 0"
      >
        <div class="container text-center">
          <h1 class="pro-tit" style="margin-bottom: 20px;font-size:36px;">成功案例</h1>
          <div class="row align-items-start">
            <div
              :class="['col', idx == index ? 'acticv' : '']"
              @click="handleSwitch(index)"
              v-for="(item, index) in tabs"
              :key="index"
            >
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="pro-wrap">
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 0"
          >
            <div class="content" style="display: flex;padding:20px 0 ;">
              <div class="wrap">
                <h6 style="font-size: 35px">
                  {{caseA.contentTitle}}
                </h6>
                <p class="p-content">
                  {{caseA.contentDescription}}
                </p>
                <div>
                  <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseA.id}&tit=${caseA.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm " 
                >
                  查看详情
                </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img :src="baseImgUrl + caseA.contentImgUrl" width="640px" height="360px"/>
              </div>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 1"
          >
            <div class="content" style="display: flex;padding:20px 0 ;">
              <div  class="wrap" style="flex: 60%">
                <h6 style="font-size:35px;">{{caseB.contentTitle}}</h6>
                <p class="p-content">
                  {{caseB.contentDescription}}
                </p>
                <div>
                  <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseB.id}&tit=${caseB.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm " 
                >
                  查看详情
                </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img :src="baseImgUrl + caseB.contentImgUrl" width="640px" height="360px"/>
              </div>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInRightBig"
            v-show="idx == 2"
          >
            <div class="content" style="display: flex;padding:20px 0 ;">
              <div  class="wrap" style="flex: 60%">
                <h6  style="font-size:35px;">{{caseC.contentTitle}}</h6>
                <p class="p-content">
                  {{caseC.contentDescription}}
                </p>
                <div>
                  <el-button
                type="primary" size="mini" round
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseC.id}&tit=${caseC.contentTitle}`);
                    }
                  "
                  class="btn btn-primary btn-sm" 
                >
                  查看详情
                </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img :src="baseImgUrl + caseC.contentImgUrl" width="640px" height="360px"/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="mobile-product d-block d-lg-none"
        style="margin-top: 40px"
      >
        <div class="container text-center">
          <h3 class="pro-tit">成功案例</h3>
          <div class="row align-items-start">
            <div
              :class="['col', idx == index ? 'acticv' : '']"
              @click="handleSwitch(index)"
              v-for="(item, index) in tabs"
              :key="index"
            >
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="pro-wrap">
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 0"
          >
            <div class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: 700; margin-bottom: 1rem">
                  {{caseA.contentTitle}}
                </h6>
                <p class="p-content">
                  {{caseA.contentDescription}}
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="baseImgUrl + caseA.contentImgUrl"/>
              </div>
              <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseA.id}&tit=${caseA.contentTitle}`);
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 1"
          >
            <div class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: bold; margin-bottom: 0.5rem">
                  {{caseB.contentTitle}}
                </h6>
                <p class="p-content">
                  {{caseB.contentDescription}}
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="baseImgUrl + caseB.contentImgUrl"/>
              </div>
              <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseB.id}&tit=${caseB.contentTitle}`);
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInRightBig"
            v-show="idx == 2"
          >
            <div class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: bold; margin-bottom: 0.5rem">
                  {{caseC.contentTitle}}
                </h6>
                <p class="p-content">
                  {{caseC.contentDescription}}
                </p>
 
 
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="baseImgUrl + caseC.contentImgUrl"/>
              </div>
              <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(`/caseDetail?id=${caseC.id}&tit=${caseC.contentTitle}`);
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </section>

 

      <right-nav-new class="d-none d-lg-block" />
    </div>

    <NewFooter @handleClickChild="handleClickChild"  />
    <MobileFooter ref="mofooter" class="d-block d-lg-none" />
  </div>
</template>
        
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);
import Qs from "qs";
import { Newslist } from "@/api/fetch";

export default {
  name: "hardware",
  metaInfo() {
    return {
      title: '优制云智能制造MES - 工业互联网智能生产管理系统',
      meta: [
        { name: 'keywords', content: '智能制造MES,工业互联网,生产溯源、品质管理、设备管理、数据采集,车间管理,数字化生产,工业SaaS系统,优制云,伟班平台' },
        { name: 'description', content: '智能制造MES是优制云工业互联网为中小微企业量身打造的智能生产管理系统，通过生产溯源、品质管理、设备管理、数据采集分析，为企业打造高效、透明的智能制造解决方案。实现从生产计划到车间执行的全方位管理，实时监控生产过程，优化资源配置，实现精益生产和智能制造。' },
        
      ],
    };
  },
  data() {
    return {
      tabs: ["华瀚管道", "数研时空", "润玉空间"],
      activeName: "1",
      idx: 0,
      tbx:0,
      ids: -1,
      tabListNew: [
        {
          img1: require("@/assets/newSite/mes/p1.png"),
          tit: "工程管理",
        },
        {
          img1: require("@/assets/newSite/mes/p2.png"),
          tit: "生产管理",
        },
        {
          img1: require("@/assets/newSite/mes/p3.png"),
          tit: "生产溯源",
        },
        {
          img1: require("@/assets/newSite/mes/p4.png"),
          tit: "品质管理",
        },
        {
          img1: require("@/assets/newSite/mes/p5.png"),
          tit: "设备管理",
        },
        {
          img1: require("@/assets/newSite/mes/p6.png"),
          tit: "数据采集",
        },
      ],
 
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      baseImgUrl:'https://admin.veiban.com',
      caseList:[],
      caseA:{},
      caseB:{},
      caseC:{},
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    this.getCase();
  },
  methods: {
    handleClickChild(){
     this.$refs.mofooter.handleDeal(2)
    },
     //成功案例
    getCase() {
      Newslist(
        Qs.stringify({ categoryId: '1839482945283117058', pageSize: 10, pageNo: 1,productCode:'mes' })
      ).then((res) => {
        if (res.data.rows.length) {
            this.caseA = res.data.rows [0]
            this.caseB = res.data.rows [1]
            this.caseC = res.data.rows [2]
            this.caseList =  res.data.rows
        }
      });
    },
    openNewWindow(path) {
      const routeData = this.$router.resolve({path,id:'123456'});
      window.open(routeData.href, "_blank");
    },
    handleSwitch(idx) {
      this.idx = idx;
    },
    handleTest() {
      window.open("https://apipecloud.veiban.com/login");
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleLeft() {
      const arrs = ["0", "1", "2", "3", "4", "5", "6", "7", "8"];
      const current = this.activeName;
      let posIdx = arrs.findIndex((n) => n == current);
      if (posIdx == 0) return;
      this.activeName = arrs[posIdx - 1];
    },
    handleRight() {
      const arrs = ["0", "1", "2", "3", "4", "5", "6", "7", "8"];
      const current = this.activeName;
      let posIdx = arrs.findIndex((n) => n == current);
      if (current == arrs.length - 1) return;
      this.activeName = arrs[posIdx + 1];
    },
    handleTab(index) {
      this.tbx = index
     
    },
    enter(index) {
      this.ids = index;
    },
    leave() {
      this.ids = -1;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, false);
  },
};
</script>
        
<style lang="less" scoped>
.apipe-cloud-course-container-madeSolution {
  .pc-banner {
    height: 600px;
    background-image: url("../../assets/newSite/madeSolution/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 18px;
        margin: 4px 0;
      }
      .exp {
        margin-top: 2rem;
        font-size: 14px;
        border: 1px solid white;
        display: inline-block;
        width: 100px;
        padding: 2px 0;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .mobile-banner {
    height: 300px;
    width: 100%;
    background-image: url("../../assets/newSite/mes/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .container {
      color: white;
      padding: 40px 15px;
      height: 100%;
      .title{
        font-size: 35px;
        text-align: left;
        color: white;
        font-family:Source Han Sans CN;
        font-weight:200;
        margin-bottom: 30px 20px 30px 20px;
      }
      .titwrap{
        padding: 30px 15px;
        .tit {
          font-size: 13px;
          margin: 5px 0;
        }        
      }

      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .pc-pro-power {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
    }
    .tabs {
      display: flex;
      .items {
        flex: 25%;
        // height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        .tit {
          padding: 20px 0 16px 0;
          font-size: 18px;
          font-weight: 700;
        }
        &:hover {
          cursor: pointer;
        }
        img {
          width: 36px;
          height: 36px;
        }
      }
      .active {
        .tit {
          border-bottom: 4px solid rgba(20, 132, 255, 1);
        }
      }
    }
    .tab-cot {
      background: #f6faff;
      padding: 20px;
      .row {
        min-height: 300px;
        padding: 20px 100px;
        .mtit {
          text-align: left;
          font-size: 24px;
          font-weight: 500;
        }
        .mcot {
          text-align: left;
          margin: 20px 0 30px 0;
          color: #333333;
        }
        .lf {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: center;
          padding: 10px;
          .btn {
            width: 120px;
            background: #1484ff;
            &:hover {
              background: #66b1ff;
            }
          }
        }
        p {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  .mobile-pro-power {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 20px;
      color: #333;
      font-weight: 700;
    }
    .tabs {
      display: flex;
      width: 375px;
      overflow-x: scroll;
      overflow-y: hidden;
      margin: 0 auto;
      .items {
        width: 33%;
        flex-shrink: 0;
        // height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        &:hover {
          cursor: pointer;
        }
        .tit {
          padding: 20px 0 16px 0;
          font-family: Source Han Sans CN;
          font-weight: 700;
          font-size: 16px;
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
      .active {
        .tit {
          border-bottom: 4px solid rgba(20, 132, 255, 1);
        }
      }
    }
    .tab-cot {
      background: #f6faff;
      padding: 20px 0;
      .row {
        min-height: 300px;
        padding: 10px;
        h4{
          font-family: Source Han Sans CN;
          font-weight: 700;
          color: #333333;
          font-size: 16px;
        }
        .lf {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: center;
          padding: 10px;
          .btn {
            width: 100px;
          }
        }
        p {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  .more-function {
    margin: 50px 0;
    .pro-tits {
      color: #666;
      margin-bottom: 30px;
      padding: 0 10px;
    }
    .col {
      .p-3 {
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        padding: 24px 0 10px 0 !important;
      }
    }

    img {
      width: 40px;
      height: 40px;
    }
    p {
      color: #888;
      font-size: 13px;
    }
    h6 {
      margin-top: 1rem;
    }
  }

  .pc-scene {
    margin: 80px 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        min-height: 200px;
        padding: 0 !important;
        // box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }
    img {
      width: 100%;
    }
    h6 {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
    }
  }
  .mobile-scene {
    margin: 0 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        padding: 5px !important;
      }
    }
    img {
      width: 100%;
    }
    h6 {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
    }
  }
  .pc-industry {
    margin: 80px 0;
    .pro-tit {
      margin-bottom: 40px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        background: #f6faff;
        height: 240px;
        width: 300px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }
    img {
      //   width: 100%;
      //   height: 200px;
    }
    h6 {
      margin-top: 0.5rem;
      font-size: 16px;
      font-weight: 700;
      color: rgba(51, 51, 51, 1);
    }
    .des {
      color: #333333;
      font-size: 14px;
    }
  }
  .mobile-industry {
    margin: 40px 0;
    .pro-tit {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 700;
      color: #333;
    }
    .col {
      .p-3 {
        background: #f6faff;
        margin:10px ;
      }
    }
    img {
      //   width: 100%;
      //   height: 200px;
    }
    h6 {
      font-family: Source Han Sans CN;
      font-weight: 200;
      color: #333333;
      font-size: 15px;
    }
    .des {
      color: #333333;
      font-size: 14px;
    }
  }
  .pc-product {
    .acticv {
      span {
        height: 4px;
        border-bottom: 4px solid #1484ff;
        color: #1484ff;
      }
    }
    .col {
      cursor: pointer;
      span {
        display: inline-block;
        height: 40px;
      }
    }
    .pro-wrap {
      background: #f6faff;
      text-align: left;
      .container {
        display: flex;

        .cot {
          background: url("../../assets/newSite/home/pc.png");
          background-repeat: no-repeat;
          background-size: cover;
          height: 702px;
          width: 100%;
        }
        img {
          // height: 16rem;
        }
        .content {
          margin: 40px 0;
          width: 100%;
          .wrap {
            flex: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
              font-family: Source Han Sans CN;
              font-weight: 200;
              color: #333333;
              width: 540px;
              margin-bottom: 20px;
              margin-top: 12px;
              font-size: 18px;
            }
          }
          .btn {
            width: 120px;
            background: #1484ff;
            height: 36px;
          }
        }
      }
    }
  }
  .mobile-product {
    .acticv {
      // border-bottom: 2px solid rgba(20, 132, 255, 1);
      span {
        height: 4px;
        border-bottom: 4px solid #1484ff;
        color: #1484ff;
      }
    }
    .col {
      padding:  10px 0 0 0;
      cursor: pointer;
      span {
        display: inline-block;
        height: 40px;
        font-family: Source Han Sans CN;
        font-weight: 700;
        font-size: 16px;
      }
    }
    .pro-tit {
      font-family: Source Han Sans CN;
      font-weight: 700;
      color: #333333;
      font-size: 20px;
      margin-bottom: 15px;
    }
    .pro-wrap {
      text-align: left;
      background: #f6faff;
      .container {
        display: flex;
        .content {
          padding: 2rem 1.5rem 1.5rem 1.5rem;
          width: 100%;
          img {
            width: 100%;
          }
          .p-content {
            color: #666;
            font-size: 0.8rem;
            font-family: "思源黑体";
            line-height: 1.6rem;
          }
          .btn {
            width: 120px;
            background: #1484ff;
            color: white;
          }
        }
      }
    }
  }

  .cooperation {
    margin-bottom: 30px;
    .pro-tit {
      margin-bottom: 30px;
    }
    .wrap {
      display: flex;
      justify-content: center;
      .items {
        margin: 0 20px;
        flex: 1;
        position: relative;
        .p-3 {
          height: 180px;
          // width: 280px !important;
          // border: 1px solid red;
          // width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          img {
            width: 50%;
          }
        }

        .des {
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
          background: rgba(20, 132, 255, 1);
          color: white;
          padding: 20px;
        }
      }
    }
  }

  .mobile-cooperation {
    margin-bottom: 30px;
    .pro-tit {
      margin-bottom: 30px;
    }
    .wrap {
      .items {
        display: flex;
        //  flex-direction: column;
        justify-content: center;
        margin: 20px 0;
        .p-3 {
          height: 150px;
          width: 280px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          img {
            width: 50%;
          }
        }
      }
    }
  }
}
</style>
        